<template>
    <div>
        
        <div class="u-resume-title">上传简历</div>
        <div class="upload-resume-warpper">
            <div class="u-has-resume flex" v-loading="loading">
                <!--                <div class="doc-pdf"><img src="../../../assets/img/users/pdf.png" alt=""></div>-->

                <div class="doc-pdf-name" v-if="resume">
                    <el-link :href="resume" target="_blank">查看/下载附件简历</el-link>
                </div>
                <!-- <div class="doc-pdf-name" v-if="resumeFileObj.name">{{resumeFileObj.name}}</div> -->

                <!-- <div class="choose-upload">立即创建</div> -->
            </div>
            <!--             <div class="u-empty-resume">-->
            <!--                    支持导入格式：DOC、DOCX、PDF、XLS、XLSX、TXT格式文件，文件大小不超过3M，暂不支持上传英文简历。-->
            <!--            </div>-->
        </div>
        <el-upload
                class="upload-box"
                action="/api/qiniu/getImageUrl"
                :before-upload="beforeResumeUpload"
                :on-success="resumeUploadSuccess"
                :show-file-list="false">
            <div class="upload-btn">上传简历</div>
            <div slot="tip" class="el-upload__tip">支持导入格式：DOC、DOCX、PDF格式文件，文件大小不超过3M，暂不支持上传英文简历。更新只需覆盖上传即可。</div>
        </el-upload>

        <!--        <div class="u-upload__button">上传简历</div>-->
        <div class="other-label flex">
            <!--            <div class="o-label-item flex" v-for="(item,index) in gridList" :key ="index">-->
            <!--                <div class="o-label-img">-->
            <!--                    <img :src="require(item.icon)" alt="">-->
            <!--                </div>-->
            <!--                    <div class="0-name">{{item.name}}</div>-->
            <!--            </div>-->
            <!--            <div class="o-label-item flex">-->
            <!--                <div class="o-label-img">-->
            <!--                    <img src="../../../assets/img/users/img-preview.png" alt="">-->
            <!--                </div>-->
            <!--                <div class="0-name">预览附件</div>-->
            <!--            </div>-->
            <!-- <div class="o-label-item flex" @click="downloadResume">
                <div class="o-label-img">
                    <img src="../../../assets/img/users/img-download.png" alt="">
                </div>
                <div class="0-name">下载附件</div>
            </div> -->
<!--            <div class="o-label-item flex">-->
<!--                <div class="o-label-img">-->
<!--                    <img src="../../../assets/img/users/resume-template.png" alt="">-->
<!--                </div>-->
<!--                <div class="0-name">投递记录</div>-->
<!--            </div>-->
            <!--            <div class="o-label-item flex">-->
            <!--                <div class="o-label-img">-->
            <!--                    <img src="../../../assets/img/users/resume-template.png" alt="">-->
            <!--                </div>-->
            <!--                <div class="0-name">投递记录</div>-->
            <!--            </div>-->
        </div>
    </div>
</template>
<script>
    import userRequest from "@/api/user";

    export default {

        created() {
            this.getResume()
        },
        mounted() {

        },
        data() {
            return {
                loading:false,
                resume:null,
                resumeFileObj: {
                    name: "",
                    fileUrl: ""
                }, // 附件简历对象
                gridList: [
                    {
                        name: "预览附件",
                        icon: "../../../assets/img/users/img-preview.png",
                        routerName: ""
                    },
                    {
                        name: "下载附件",
                        icon: "../../../assets/img/users/img-download.png",
                        routerName: ""
                    },
                    {
                        name: "投递记录",
                        icon: "../../../assets/img/users/resume-template.png",
                        routerName: ""
                    },
                ]
            }
        },
        methods: {
            getResume(){
                this.loading=true
                userRequest.getUserInfo().then(res => {
                    this.loading=false
                    console.log("获取到的用户的基本信息", res)
                    if(res.code==200){
                        this.resume=res.data.resume
                    }
                })
            },
            beforeResumeUpload(file) {
                // const isWord = file.type==='pdf/doc/docx'
            },
            resumeUploadSuccess(res, file, fileList) {
                console.log("上传文件的返回值", res, file, fileList)
                if(res.code === 200){
                    userRequest.uploadResumeUrl({resume:res.data.message}).then(result=>{
                        console.log("上传附件简历的地址",result)
                        this.getResume()
                    })
                    
                }
                this.resumeFileObj = {
                    name: file.name,
                    fileUrl: file.response.data.message
                }
            },
            downloadResume(){

            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "../../../assets/css/global.scss";

    .upload-box {
        width: 100%;

        .upload-btn {
            width: 240px;
            background-color: $main;
            color: #FFFFFF;
            height: 40px;
            line-height: 40px;
            border-radius: 5px;
        }

        .el-upload__tip {
            font-size: 14px;
            letter-spacing: 0.1em;
            line-height: 1.6em;
            text-indent: 2em;
        }
    }


    .u-resume-title {
        font-size: 18px;
        line-height: 18px;
        color: #333;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .upload-resume-warpper {
        .u-has-resume {
            padding: 10px;
            align-items: center;
            margin-bottom: 27px;

            .doc-pdf {
                width: 35px;
                height: 35px;
                padding: 0 13px;

                img {
                    width: 35px;
                    height: 35px;
                }
            }

            .doc-pdf-name {
                line-height: 18px;
                color: #333;
            }
        }

        .u-empty-resume {
            margin-bottom: 27px;
            font-size: 14px;
            color: #666;
            letter-spacing: 0;
            line-height: 26px;
        }
    }

    .u-upload__button {
        cursor: pointer;
        width: 100%;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: $main;
        border-radius: 20px;
        font-size: 14px;
        color: #fff;

        &:hover {
            background: $hover;
        }
    }

    .other-label {
        margin-top: 30px;
        // justify-content: center;
        flex-wrap: wrap;

        .o-label-item {
            cursor: pointer;
            width: 33.3333%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            &:hover {
                color: $main;
            }

            .o-label-img {
                width: 36px;
                height: 36px;
                margin-bottom: 10px;

                img {
                    width: 36px;
                    height: 36px;
                }
            }
        }
    }
</style>