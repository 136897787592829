<template>
    <div class="delivery-record-warpper user-content flex">
        <div class="delivery-list">
            <div class="d-list-header flex">
                <div class="l-header-item" :class="{'dl-active': searchType===0}" @click="changeSearch(0)">投递成功</div>
                <div class="l-header-item" :class="{'dl-active': searchType===1}" @click="changeSearch(1)">邀面试</div>
                <div class="l-header-item" :class="{'dl-active': searchType===2}" @click="changeSearch(2)">不合适</div>
            </div>
            <div class="d-list-warpper">
                <div class="d-list" v-if="resumeList.length>0">
                    <div class="delivery-item flex">
                        <div class="d-item-base">
                            <div class="b-jobname text-overflow">Node.js开发工程师(可兼职）Node.js开发工程师(可兼职）Node.js开发工程师(可兼职）</div>
                            <div class="d-base-sales">15000元</div>
                            <div class="d-base-cname">腾讯之宇宙大厂</div>
                        </div>
                        <div class="d-item-other flex">
                            <div class="d-other-status">成功投递</div>
                            <div class="d-other-ctime">2020-11-25</div>
                        </div>
                    </div>
                    <div class="delivery-item flex">
                        <div class="d-item-base">
                            <div class="b-jobname text-overflow">Node.js开发工程师(可兼职）Node.js开发工程师(可兼职）Node.js开发工程师(可兼职）</div>
                            <div class="d-base-sales">15000元</div>
                            <div class="d-base-cname">腾讯之宇宙大厂</div>
                        </div>
                        <div class="d-item-other flex">
                            <div class="d-other-status">成功投递</div>
                            <div class="d-other-ctime">2020-11-25</div>
                        </div>
                    </div>
                    <div class="delivery-item flex">
                        <div class="d-item-base">
                            <div class="b-jobname text-overflow">Node.js开发工程师(可兼职）Node.js开发工程师(可兼职）Node.js开发工程师(可兼职）</div>
                            <div class="d-base-sales">15000元</div>
                            <div class="d-base-cname">腾讯之宇宙大厂</div>
                        </div>
                        <div class="d-item-other flex">
                            <div class="d-other-status">成功投递</div>
                            <div class="d-other-ctime">2020-11-25</div>
                        </div>
                    </div>
                    <div class="delivery-item flex">
                        <div class="d-item-base">
                            <div class="b-jobname text-overflow">Node.js开发工程师(可兼职）Node.js开发工程师(可兼职）Node.js开发工程师(可兼职）</div>
                            <div class="d-base-sales">15000元</div>
                            <div class="d-base-cname">腾讯之宇宙大厂</div>
                        </div>
                        <div class="d-item-other flex">
                            <div class="d-other-status">成功投递</div>
                            <div class="d-other-ctime">2020-11-25</div>
                        </div>
                    </div>
                    <div class="delivery-item flex">
                        <div class="d-item-base">
                            <div class="b-jobname text-overflow">Node.js开发工程师(可兼职）Node.js开发工程师(可兼职）Node.js开发工程师(可兼职）</div>
                            <div class="d-base-sales">15000元</div>
                            <div class="d-base-cname">腾讯之宇宙大厂</div>
                        </div>
                        <div class="d-item-other flex">
                            <div class="d-other-status">成功投递</div>
                            <div class="d-other-ctime">2020-11-25</div>
                        </div>
                    </div>
                    <div class="delivery-item flex">
                        <div class="d-item-base">
                            <div class="b-jobname text-overflow">Node.js开发工程师(可兼职）Node.js开发工程师(可兼职）Node.js开发工程师(可兼职）</div>
                            <div class="d-base-sales">15000元</div>
                            <div class="d-base-cname">腾讯之宇宙大厂</div>
                        </div>
                        <div class="d-item-other flex">
                            <div class="d-other-status">成功投递</div>
                            <div class="d-other-ctime">2020-11-25</div>
                        </div>
                    </div>
                </div>
                <div class="d-list-no flex" v-else>
                    <div class="l-no-img">
                        <img src="../../../assets/img/company/empty.png" alt="">
                    </div>
                    <div class="l-no-text">列表空空如也~</div>
                </div>
            </div>
        </div>
        <div class="sider-resume">
            <upload-pdf></upload-pdf>
        </div>
    </div>
</template>
<script>
import UploadPdf from '../component/UploadPdf'
export default {
    data() {
        return {
            searchType: 0,
            resumeList: [1]
        }
    },
    methods: {
        changeSearch(index) {
            this.searchType = index
        }
    },
    components: {
        UploadPdf  
    } 
}
</script>
<style lang="scss" scoped>
    @import "../../../assets/css/global.scss";
    .delivery-record-warpper{
        // height: 100%;
        margin-top: 20px;
        .delivery-list{
            box-sizing: border-box;
            width: 884px;
            // background: #fff;
            .d-list-header{
                width: 884px;
                height: 46px;
                background: #fff;
                .l-header-item{
                    user-select: none;
                    cursor: pointer;
                    flex: 1;
                    text-align: center;
                    line-height: 46px;
                    border-right: 1px solid #f0f0f0;
                    &:hover{
                        color: $main;
                    }
                    &.dl-active{
                        font-weight: 700;
                        color: $main;
                    }
                }
            }
        }
        .d-list-warpper{
            min-height: 400px;
            margin-top: 20px;
            padding-bottom: 20px;
            // padding: 20px;
            background: #fff;
            .d-list{
                .delivery-item{
                    justify-content: space-between;
                    padding: 30px 25px 20px 25px;
                    border: 1px solid #f8f8f8;
                    .d-item-base{
                        max-width: 350px;
                        .b-jobname{
                            font-size: 18px;
                            color: #333;
                        }
                        .d-base-sales{
                            margin: 8px 0 3px;
                            font-size: 18px;
                            color: $main;
                            font-weight: 700;
                        }
                        .d-base-cname{
                            margin-top: 10px;
                            font-size: 14px;
                            color: #999;
                        }
                    }
                    .d-item-other{
                        flex-direction: column;
                        align-items: flex-end;
                        .d-other-status{
                            font-size: 14px;
                            color: #666;
                            height: 47px;
                        } 
                        .d-other-ctime{
                            color: #999;
                        } 
                    }
                }
            }
            .d-list-no{
                padding-top: 100px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .l-no-text{
                    margin-top: 15px;
                    color: #999;
                }
            }
        }
        .sider-resume{
            // margin-top: 20px;
            box-sizing: border-box;
            width: 300px;
            padding: 20px;
            background: #fff;
            margin-left: 14px;
        }
    }
</style>